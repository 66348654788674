<template>
  <div class="form-box">
    <FormTitle :infoName="infoName" />
    <div class="form-list">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请输入问卷主题">
          <el-input v-model="ruleForm.title"
                    type="text"
                    placeholder="请输入问卷主题"
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>
        <div class="box-g">
          <el-form-item class="lis-g lis-pc-show" label="问卷类型">
            <el-select filterable v-model="ruleForm.extParam.questionnaireType" placeholder="请选择">
              <el-option
                  v-for="(item, index) in questionnaireInfo.taget"
                  :label="item"
                  :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="lis-g lis-pc-show" label="题目数量">
            <el-select filterable v-model="ruleForm.extParam.questionnaireNum" placeholder="请选择">
              <el-option
                  v-for="(item, index) in questionnaireInfo.num"
                  :label="item"
                  :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="lis-g lis-h5-show" label="问卷类型">
            <el-select v-model="ruleForm.extParam.questionnaireType" placeholder="请选择">
              <el-option
                v-for="(item, index) in questionnaireInfo.taget"
                :label="item"
                :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="lis-g lis-h5-show" label="题目数量">
            <el-select v-model="ruleForm.extParam.questionnaireNum" placeholder="请选择">
              <el-option
                v-for="(item, index) in questionnaireInfo.num"
                :label="item"
                :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="lis-g" label="问卷对象">
            <el-input v-model="ruleForm.extParam.questionnaireTarget"
                      type="text"
                      placeholder="请输入问卷对象"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="问卷用途">
          <el-input type="textarea"
            v-model="ruleForm.extParam.questionnairePurpose"
            :rows="4"
            placeholder="描述此次问卷用途，比如用在投票选举中，需要创作的问题包含哪些方面"
            maxlength="150"
            show-word-limit></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />
  </div>
</template>

<script>
  import QuestionnaireApi from "@/api/questionnaire"
  import CommonApi from "@/api/common"
  import { orderMixin } from '@/utils/orderMixin'
  import { validateTitle } from '@/utils/validate'
  import FormTitle from "@/components/FormTitle/index.vue"
  import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
  export default {
    components: {NoticeWrapper, FormTitle},
    mixins: [orderMixin],
    props: ['infoName'],
		data() {
			return {
        ruleForm: {
          title: '',
          creationDocId: 8,
          creationDocCode: 'QUESTIONNAIRE',
          channelCode: null,
          extParam: {
            version: 1,
            modelCode: 1,
            questionnaireType: '评估性问卷',
            questionnaireNum: 10,
            questionnaireTarget: '',
            questionnairePurpose: ''
          }
        },
        // rules: {
        //   title: [
        //     { required: true, message: '请输入问卷主题', trigger: 'blur' },
        //     { validator: validateTitle, trigger: 'blur' }
        //   ],
        //   extParam: {
        //     questionnaireType: [
        //       { required: true, message: '请选择问卷类型', trigger: 'change' }
        //     ],
        //     questionnaireNum: [
        //       { required: true, message: '请选择题目数量', trigger: 'change' }
        //     ],
        //     questionnaireTarget: [
        //       { required: true, message: '请输入问卷对象', trigger: 'blur' }
        //     ],
        //     questionnairePurpose: [
        //       { required: true, message: '请输入问卷用途', trigger: 'blur' }
        //     ]
        //   }
        // },
        btnInfo: {
          title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
          checked: true
        },
        questionnaireInfo: {
          num: '',
          taget: ''
        },
        paramsData: '', // 储存跳页面的传值
			}
		},
		created() {
      this.getQuestionnaireNum()
      this.getType()
    },
		methods: {
      //获取题目数量
      getQuestionnaireNum(){
        QuestionnaireApi.getQuestionnaireNum().then(res => {
          this.questionnaireInfo.num = res.data;
        }).catch(() => {
        })
      },
      //获取类型
      getType(){
        QuestionnaireApi.getType().then(res => {
          this.questionnaireInfo.taget = res.data;
        }).catch(() => {
        })
      },
      submitForm(formName) {
        let _this = this

        if(_this.ruleForm.title === ''){
          return _this.$message({
            message: '请输入问卷主题',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }else if(_this.ruleForm.title.length < 5){
          return _this.$message({
            message: '主题长度需要至少5个字!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }
        if(_this.ruleForm.extParam.questionnaireTarget === ''){
          return _this.$message({
            message: '请输入问卷对象',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }
        if(_this.ruleForm.extParam.questionnairePurpose === ''){
          return _this.$message({
            message: '请输入问卷用途',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }

        _this.paramsData = _this.ruleForm
        let info = JSON.stringify(_this.ruleForm.extParam)
        _this.ruleForm.extParam = info
        //生成订单 mixin
        _this.createOrder(_this.ruleForm,_this.paramsData)

        // _this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     _this.paramsData = _this.ruleForm
        //     let info = JSON.stringify(_this.ruleForm.extParam)
        //     _this.ruleForm.extParam = info
        //     //生成订单 mixin
        //     _this.createOrder(_this.ruleForm,_this.paramsData)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // }
		}
	}
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
.box-g{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  .lis-g{
    width: 33%;
    .el-select{
      width: 90%;
    }
  }
  .lis-pc-show{
    display: block;
  }
  .lis-h5-show{
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .box-g{
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .lis-g{
      width: 100%;
      .el-select{
        width: 100%;
      }
    }
    .lis-pc-show{
      display: none;
    }
    .lis-h5-show{
      display: block;
    }
  }
}
</style>
