import request from '@/utils/request'

export default {
  // 获取题目数量
  getQuestionnaireNum(){
    return request({
      url: '/questionnaire/getQuestionnaireNum',
      method: 'get'
    })
  },
  // 获取类型
  getType(){
    return request({
      url: '/questionnaire/getType',
      method: 'get'
    })
  }
}
